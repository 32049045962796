@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Maven+Pro:wght@400..900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

:root {
  /* --color-1: #f5f5f5;
  --color-2: #3E414A; 
  --color-3: #B0B0B0;
  --color-8: #FF0066;
  --color-4: #bc9a5a;
  --color-6: #041F3E;
  --color-7: #1A747D;
  --color-5: #FF4F00; */

  --font-default: "Roboto", sans-serif;
  --font-header: "Josefin Sans", sans-serif;
  --primary-background-color: #89CFF0;
  --second-background-color:  #180048;
  --third-background-color:  #100030;
  --primary-color: #180048;
  --second-color: #60f0f8;
  --third-color: #100030;

  --bs-body-font-family: "Open Sans", sans-serif;
  /* --font-header: "Maven Pro", sans-serif; */

}

.primary-background {
  /* background-color: var(--primary-color); */
  background: rgb(160,204,242);
}


.second-background-color {
  background-color: var(--second-background-color);
}

.third-background-color { background-color: var(--third-background-color); } 


.second-color {
  color: var(--second-color);
}

.third-color {
  color: var(--third-color);
}

.inverse-background-color {
  background-color: white;
  color: var(--primary-background-color);
}

.navbar-brand {
  color: var(--primary-color)!important;
  font-family: var(--font-header)!important;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.carousel-caption {
  position: inherit;
  font-family: var(--font-header);
  
}


body {
  background-color: var(--primary-background-color);
  /* background-color: white;  */
  margin: 0;
  font-family: var(--font-default);
  font-size: 1rem;

  color: var(--primary-color);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 300;
  line-height: 2.7rem;
}

h1, h2, h3, h4 {
  font-family: var(--font-header);
}


strong {
  font-weight: 600;
  color: #0d0d0d;
}

h4 {
  margin-bottom: .5rem;
  margin-top: 1rem;
  font-size: 16px;
  line-height: 1.5;
}

p,li {
  font-family: var(--font-default);
  font-size: 16px;
  line-height: 1.75;
  color: #0d0d0d;
}

.header-logo-text {
  color: #000;
  font-family: var(--font-header);
  font-size: 2rem;
}

/* section {
  padding: 20px 0px;
} */

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}


.nav-link, .nav-item, .nav-link a {
  
  /* font-weight: 600; */
  font-size: 16px;
  color: #0d0d0d;
  /* font-weight: 400; */
  font-size: 18px;
}

.nav-underline .nav-link.active, .nav-underline .show>.nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom: none;
}

.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}

.top-bar {
  /* background-color: #16163f; */
  /* padding: 0px 12px; */
  font-size: 14px;
  /* color: white; */
  font-weight: 400;
  text-transform: uppercase;
}

.top-bar .fa {
  margin-left: 4px ;
  padding-right: 4px;
  padding-left: 4px;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
  font-family: var(--font-default);

}
ul {
  padding: 0;
  margin-left: 30px;
  /* list-style-type: none; */
  font-family: var(--font-default);

}




.page-title-box {
  background: rgb(63,94,251);
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);

  /* background: rgb(238,174,202);
background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%); */

  /*
  background: rgb(131,58,180);
background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
*/

  height: 160px;
}

h1.page-title {
  margin-top: 20px;
  font-size: 3rem;
  font-weight: 800;
  text-transform: uppercase;
}

.starter {
  margin-top: -46px!important;

  -webkit-box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
}


.background-blend {
  background-color: rgb(2, 72, 146,0.3)!important;
  background-blend-mode: multiply;
  background-size: cover;
  background-position-y: center;
}

.text-blend {
  background-color: rgb(255, 255, 255,0.3)!important;
  background-blend-mode: multiply;
  backdrop-filter: blur(7px);
  padding: 8px;
}

.calendly-inline-widget {
  min-width:320px;
  height:700px;
  
}

no_mob {
  display: block;
}

.mob_only {
  display: none;
}

.logo {
  height: 45px;
}


/*<500px S*/
@media only screen and (max-width: 499px) { 
 
  .no_mob {
    display: none;
  }
  
  .mob_only {
    display: inline-grid;
  }

  .header-logo-text {
    font-size: 1.2rem;
    margin-left: 6px;
    margin-top: 10px;
  }

  .logo {
    height: 30px;
  }


}

/*500-1200 M*/
@media only screen and (min-width: 500px) and (max-width: 1199px) { 
  .header-logo-text {
    font-size: 1.5rem;
    margin-left: 5px;
  }
  .no_mob {
    display: none;
  }
  
  .mob_only {
    display: inline-grid;
  }

  .logo {
    height: 1rem;
  }
}

/*>1200px L*/
@media only screen and (min-width: 1200px) {
  
 }
